import ListingGridItem from '@/features/global-search-banner/components/listing-grid-item/listing-grid-item';
import { DEFAULT_LISTING_PART_VARIANTS } from '@/features/listing/constants/listing-constants';
import { type ListingType } from '@/features/listing/types/listing-types';
import getTypedObjectKeys from '@/shared/utils/get-typed-object-keys';

import styles from './listing-grid.module.scss';

export const SERVICE_TYPE_MAP_MAIN = getTypedObjectKeys(DEFAULT_LISTING_PART_VARIANTS);

interface ListingGridProps {
  listingType: ListingType
}

export default function ListingGrid(props: ListingGridProps) {
  const { listingType } = props;

  return (
    <div className={styles.listingGrid}>
      {SERVICE_TYPE_MAP_MAIN.map((serviceType) => (
        <ListingGridItem
          listingType={listingType}
          key={serviceType}
          name={serviceType}
        />
      ))}
    </div>
  );
}
