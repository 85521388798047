'use client';

import cx from 'classix';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';

import { DEFAULT_LISTING_PART_VARIANTS } from '@/features/listing/constants/listing-constants';
import { setCookieServiceType } from '@/features/listing/cookies/cookie-service-type';
import { type ListingType } from '@/features/listing/types/listing-types';
import { getCookieCurrentPlace } from '@/features/place/cookies/cookie-current-place';
import placeNameToUrl from '@/features/place/utils/place-name-to-url';
import { Link } from '@/navigation';

import styles from './listing-grid-item.module.scss';

interface ListingGridItemProps {
  listingType: ListingType;
  name: ListingType;
}

export default function ListingGridItem(props: ListingGridItemProps) {
  const {
    listingType,
    name,
  } = props;

  const t = useTranslations('home.listing.service-type-landing');
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  let placeLink = 'anywhere';

  const handleNewListingType = () => {
    setCookieServiceType(name);
  };

  const currentPlace = getCookieCurrentPlace();

  if (currentPlace) {
    placeLink = placeNameToUrl(currentPlace.fullName);
  }

  return (
    <Link
      href={`/${DEFAULT_LISTING_PART_VARIANTS[name]}/${placeLink}`}
      ref={hoverRef}
      className={cx(
        styles.listingGridItem,
        name === listingType.toLowerCase() && styles.listingGridItem_choose,
      )}
      onClick={handleNewListingType}
      scroll
    >
      <div className={styles.listingGridItem_imageWrapper}>
        <Image
          src={isHover || name === listingType.toLowerCase()
            ? `/images/service-types/${name}-black.svg`
            : `/images/service-types/${name}.svg`}
          alt={`${name}-image`}
          fill
        />
      </div>
      <span className={styles.listingGridItem_name}>{t(name)}</span>
    </Link>
  );
}
