'use client';

import { useTranslations } from 'next-intl';
import { useState } from 'react';

import Banner from '@/features/global-search-banner/components/banner/banner';
import SearchSlider from '@/features/global-search-banner/components/search-slider/search-slider';
import CreateListing from '@/features/listing/components/create-listing/create-listing';
import { type ListingType } from '@/features/listing/types/listing-types';
import { type Place } from '@/features/place/types/place-types';

import styles from './home-intro-section.module.scss';

interface HomeIntroSectionProps {
  place: Place | null
  listingType: ListingType
  lastSearchedPlaces: Place[] | null;
}

export default function HomeIntroSection(props: HomeIntroSectionProps) {
  const {
    lastSearchedPlaces,
    listingType,
    place,
  } = props;

  const t: (key: `title-${ListingType}` | `subtitle-${ListingType}`) => string = useTranslations('home.listing');

  function getTranslatedText(key: 'title' | 'subtitle') {
    return t(`${key}-${listingType}`);
  }

  const [showServiceType,
    setShowServiceType] = useState<boolean>(false);

  return (
    <div className={styles.homeIntroSection}>
      <div className={styles.homeIntroSection_content}>
        <div className={styles.homeIntroSection_search}>
          <div className={styles.homeIntroSection_wrapper}>
            <h1>{getTranslatedText('title')}</h1>
            <p>{getTranslatedText('subtitle')}</p>
          </div>
          <SearchSlider
            showServiceType={showServiceType}
            setShowServiceType={setShowServiceType}
            listingType={listingType}
            place={place}
            lastSearchedPlaces={lastSearchedPlaces}
          />
        </div>
        <div className={styles.homeIntroSection_banner}>
          <Banner isShow={!showServiceType} />
        </div>
      </div>
      <CreateListing isShow={!showServiceType} />
    </div>
  );
}
