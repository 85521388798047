'use client';

import cx from 'classix';
import { BsArrowLeft } from 'react-icons/bs';

import ListingGrid from '@/features/global-search-banner/components/listing-grid/listing-grid';
import { type ListingType } from '@/features/listing/types/listing-types';
import SearchPlace from '@/features/place/components/search-place/search-place';
import { type Place } from '@/features/place/types/place-types';
import Button from '@/ui/button/button';

import styles from './search-slider.module.scss';

interface SearchSliderProps {
  place: Place | null
  listingType: ListingType
  lastSearchedPlaces: Place[] | null;
  showServiceType: boolean;
  setShowServiceType: (value: boolean) => void;
}

export default function SearchSlider(props: SearchSliderProps) {
  const {
    lastSearchedPlaces,
    listingType,
    place,
    setShowServiceType,
    showServiceType,
  } = props;

  const moveServiceTypeStage = () => {
    setShowServiceType(true);
  };

  const backToSearchStage = () => {
    setShowServiceType(false);
  };

  return (
    <div className={styles.container}>
      <div className={cx(styles.contentWrapper, showServiceType && styles.shiftLeft)}>
        <div className={styles.content}>
          <div className={styles.inputGroup}>
            <SearchPlace
              currentPlace={place}
              lastSearchedPlaces={lastSearchedPlaces}
              isNeedMoveForwardButton
              moveForwardButtonCallback={moveServiceTypeStage}
              isMainPage
            />
          </div>
          <div className={cx(styles.replacementContent, !showServiceType && styles.hidden)}>
            <Button
              className={styles.replacementContentButton}
              name="back"
              aria-label="back"
              onClick={backToSearchStage}
              type="button"
              element="button"
              shade="light"
              size="lg"
              variant="ghost"
            >
              <BsArrowLeft />
            </Button>
            <ListingGrid listingType={listingType} />
          </div>
        </div>
      </div>
    </div>
  );
}
